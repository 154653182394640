import './index.css'

const About = () => {
    return (
      <section id="about" class="css-hxrw9y" style={{marginBottom: '5rem'}}>
          <h2 class="css-149wzhi">
            Our Story
          </h2>
          <div class="css-1macpfm" style={{maxWidth: "100%"}}>
            Foundet in 2013 in Walldorf, Germany, the Exoriens GmbH focused on developing software and projects for SAP customers. 
            After moving to Berlin, we also implemented Health care projects and founded the Exoriens LLC in Tbilis which is a 100% subsidery of us.
            Now these two hearths are beating in us and giving us the unique capabilities to bridge the two worlds and to bring all our industry 
            experience into the health care sector.
          </div>
        </section>
    )
}

export default About
