import './index.css'
import logo from './logo.png'
const Logo = () => {
    return (
      <img src={logo} alt="" className="logo"/>

    )
}

export default Logo
