import React, { useEffect, useState } from "react";
import Icon from "../../../Components/Icon";
import "./index.css";
import { setTheme } from "../../../Utils/themes";
import Logo from "../../../Components/Logo";

const Header = () => {
     const [togClass, setTogClass] = useState('dark');
     let theme = localStorage.getItem('theme');

     const handleOnClick = () => {
          if (localStorage.getItem('theme') === 'theme-dark') {
               setTheme('theme-light');
               setTogClass('light')
          } else {
               setTheme('theme-dark');
               setTogClass('dark')
          }
     }

     useEffect(() => {
          if (localStorage.getItem('theme') === 'theme-dark') {
               setTogClass('dark')
          } else if (localStorage.getItem('theme') === 'theme-light') {
               setTogClass('light')
          }
     }, [theme])

     const [isOpen, setIsOpen] = useState(false);
     return (
          <header>
               <div className="navbar-container">
                    <div className="navbar-logo">
                         <a href="" className="navbar-logo-link">
                              <Logo />
                              <span className="title">exoriens</span>
                         </a>
                         <span className="badge-gray">Tbilisi</span>
                    </div>
                    <span className="css-zim5tm">
                         <a className="css-ikspt1" href="#about">
                              About
                         </a>
                    </span>
                    {/*<span className="css-zim5tm">
                         <a className="css-ikspt1" href="#news">
                              News
                         </a>
                    </span>*/}
                    <a className="css-1ugvmpi" href="#positions">
                         <span className="css-whh5e5">Open Positions</span>
                    </a>
                    <button className="css-5wkvlu"onClick={handleOnClick}>
                         <Icon type={togClass === "light" ? "moon" : "sun"} />
                    </button>
               </div>
          </header>
     );
};

export default Header;
