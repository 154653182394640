import React from 'react'
import Feature from '../../Components/feature'
import './index.css'

const Features = () => {
    return (
        <section class="css-1lxgj40">
            <h2 class="css-4ioq5i">
                We want the best. 
                <span class="css-1y28u0q"> We want you.</span>
            </h2>
            <p class="css-9gyhfr">
                We really would like to know you!<br />If you got an engineering mindset, please reach out to us, we constantly hire.
            </p>
            <a style={{ marginTop: "0px" }} class="css-1s3bb42" href="mailto:georgia@exoriens.net">
                <span class="css-whh5e5">
                    contact us
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-label="Arrow right" role="img"
                        fill="none" stroke="currentColor">
                        <defs>
                            <linearGradient id="ArrowR-grad1" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad1-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad1-2)"></stop>
                            </linearGradient>
                            <linearGradient id="ArrowR-grad2" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad2-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad2-2)"></stop>
                            </linearGradient>
                            <linearGradient id="ArrowR-grad3" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad3-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad3-2)"></stop>
                            </linearGradient>
                            <linearGradient id="ArrowR-grad4" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad4-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad4-2)"></stop>
                            </linearGradient>
                            <linearGradient id="ArrowR-grad5" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad5-2)"></stop>
                                <stop offset="100%" stop-color="var(--grad5-1)"></stop>
                            </linearGradient>
                            <linearGradient id="ArrowR-logo" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad1-2)"></stop>
                                <stop offset="100%" stop-color="var(--grad1-1)"></stop>
                            </linearGradient>
                        </defs>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </span>
            </a>
        </section>
    )
}

export default Features
