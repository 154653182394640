import './index.css'
const Icon = ({ type }) => {
    return (
        <>
            {(() => {
                switch (type) {
                    case 'burger':
                        return (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-label="Hamburger" role="img" fill="none" stroke="currentColor" className="css-1yv7vk9" >
                                <defs>
                                    <linearGradient id="Hamburger-grad1" x1="0%" x2="50%" y1="0%" y2="71.9%" >
                                        <stop offset="0%" stopColor="var(--grad1-1)" />
                                        <stop offset="100%" stopColor="var(--grad1-2)" />
                                    </linearGradient> <linearGradient id="Hamburger-grad2" x1="0%" x2="50%" y1="0%" y2="71.9%" >
                                        <stop offset="0%" stopColor="var(--grad2-1)" />
                                        <stop offset="100%" stopColor="var(--grad2-2)" />
                                    </linearGradient>
                                    <linearGradient id="Hamburger-grad3" x1="0%" x2="50%" y1="0%" y2="71.9%" >
                                        <stop offset="0%" stopColor="var(--grad3-1)" />
                                        <stop offset="100%" stopColor="var(--grad3-2)" />
                                    </linearGradient>
                                    <linearGradient id="Hamburger-grad4" x1="0%" x2="50%" y1="0%" y2="71.9%" >
                                        <stop offset="0%" stopColor="var(--grad4-1)" />
                                        <stop offset="100%" stopColor="var(--grad4-2)" />
                                    </linearGradient>
                                    <linearGradient id="Hamburger-grad5" x1="0%" x2="50%" y1="0%" y2="71.9%" >
                                        <stop offset="0%" stopColor="var(--grad5-2)" />
                                        <stop offset="100%" stopColor="var(--grad5-1)" />
                                    </linearGradient>
                                    <linearGradient id="Hamburger-logo" x1="0%" x2="50%" y1="0%" y2="71.9%" >
                                        <stop offset="0%" stopColor="var(--grad1-2)" />
                                        <stop offset="100%" stopColor="var(--grad1-1)" />
                                    </linearGradient>
                                </defs>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        )
                    case 'close':
                        return (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-label="Close" role="img" fill="none" stroke="currentColor" className="close-icon" >
                                <defs>
                                    <linearGradient id="Close-grad1" x1="0%" x2="50%" y1="0%" y2="71.9%" >
                                        <stop offset="0%" stopColor="var(--grad1-1)" />
                                        <stop offset="100%" stopColor="var(--grad1-2)" />
                                    </linearGradient>
                                    <linearGradient id="Close-grad2" x1="0%" x2="50%" y1="0%" y2="71.9%" >
                                        <stop offset="0%" stopColor="var(--grad2-1)" />
                                        <stop offset="100%" stopColor="var(--grad2-2)" />
                                    </linearGradient> <linearGradient id="Close-grad3" x1="0%" x2="50%" y1="0%" y2="71.9%" >
                                        <stop offset="0%" stopColor="var(--grad3-1)" />
                                        <stop offset="100%" stopColor="var(--grad3-2)" />
                                    </linearGradient>
                                    <linearGradient id="Close-grad4" x1="0%" x2="50%" y1="0%" y2="71.9%" >
                                        <stop offset="0%" stopColor="var(--grad4-1)" />
                                        <stop offset="100%" stopColor="var(--grad4-2)" />
                                    </linearGradient>
                                    <linearGradient id="Close-grad5" x1="0%" x2="50%" y1="0%" y2="71.9%" >
                                        <stop offset="0%" stopColor="var(--grad5-2)" />
                                        <stop offset="100%" stopColor="var(--grad5-1)" />
                                    </linearGradient>
                                    <linearGradient id="Close-logo" x1="0%" x2="50%" y1="0%" y2="71.9%" >
                                        <stop offset="0%" stopColor="var(--grad1-2)" />
                                        <stop offset="100%" stopColor="var(--grad1-1)" />
                                    </linearGradient>
                                </defs>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        )
                    case 'moon':
                        return (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-label="Dark Mode" role="img" fill="none" stroke="currentColor" className="icon">
                                <defs>
                                    <linearGradient id="DarkMode-grad1" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                        <stop offset="0%" stopColor="var(--grad1-1)" />
                                        <stop offset="100%" stopColor="var(--grad1-2)" />
                                    </linearGradient>
                                    <linearGradient id="DarkMode-grad2" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                        <stop offset="0%" stopColor="var(--grad2-1)" />
                                        <stop offset="100%" stopColor="var(--grad2-2)" />
                                    </linearGradient>
                                    <linearGradient id="DarkMode-grad3" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                        <stop offset="0%" stopColor="var(--grad3-1)" />
                                        <stop offset="100%" stopColor="var(--grad3-2)" />
                                    </linearGradient>
                                    <linearGradient id="DarkMode-grad4" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                        <stop offset="0%" stopColor="var(--grad4-1)" />
                                        <stop offset="100%" stopColor="var(--grad4-2)" />
                                    </linearGradient>
                                    <linearGradient id="DarkMode-grad5" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                        <stop offset="0%" stopColor="var(--grad5-2)" />
                                        <stop offset="100%" stopColor="var(--grad5-1)" />
                                    </linearGradient>
                                    <linearGradient id="DarkMode-logo" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                        <stop offset="0%" stopColor="var(--grad1-2)" />
                                        <stop offset="100%" stopColor="var(--grad1-1)" />
                                    </linearGradient>
                                </defs>
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                                />
                            </svg>

                        )
                    case 'sun':
                        return (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-label="Light Mode" role="img" fill="none" stroke="currentColor" class="icon">
                                <defs>
                                    <linearGradient id="LightMode-grad1" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                        <stop offset="0%" stop-color="var(--grad1-1)"></stop>
                                        <stop offset="100%" stop-color="var(--grad1-2)"></stop>
                                    </linearGradient>
                                    <linearGradient id="LightMode-grad2" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                        <stop offset="0%" stop-color="var(--grad2-1)"></stop>
                                        <stop offset="100%" stop-color="var(--grad2-2)"></stop>
                                    </linearGradient>
                                    <linearGradient id="LightMode-grad3" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                        <stop offset="0%" stop-color="var(--grad3-1)"></stop>
                                        <stop offset="100%" stop-color="var(--grad3-2)"></stop>
                                    </linearGradient>
                                    <linearGradient id="LightMode-grad4" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                        <stop offset="0%" stop-color="var(--grad4-1)"></stop>
                                        <stop offset="100%" stop-color="var(--grad4-2)"></stop>
                                    </linearGradient>
                                    <linearGradient id="LightMode-grad5" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                        <stop offset="0%" stop-color="var(--grad5-2)"></stop>
                                        <stop offset="100%" stop-color="var(--grad5-1)"></stop>
                                    </linearGradient>
                                    <linearGradient id="LightMode-logo" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                        <stop offset="0%" stop-color="var(--grad1-2)"></stop>
                                        <stop offset="100%" stop-color="var(--grad1-1)"></stop>
                                    </linearGradient>
                                </defs>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"></path>
                            </svg>
                        )
                }

            })()}
        </>


    )
}

export default Icon
