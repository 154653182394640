import './index.css';
const Stats = () => {
    return (
        <section class="css-ql4xnq">
            <div>
                <h2 class="css-hx6r0">SAP</h2>
                <div class="css-niiqs5">
                    <p class="css-1h3py4p">
                        We code in ABAP OO, CDS Views in ABAP AS, S4 or on BW/4HANA. Our heart beats for SQL Script & SAP HANA, where we helped set up the world's first customer projects within SAP. 
                        <br class="css-1xjcnht" /><a href="https://www.xgile.com">Find out more</a>
                    </p>
                    
                </div>
            </div>
            <div>
                <h2 class="css-hx6r0">Fullstack App Development</h2>
                <ul class="css-4oqmf2">
                    <li>
                        <h3 class="css-4ioq5i">
                            <span class="css-1rsqs3x">Flutter</span>
                        </h3>
                        <p class="css-1h3py4p">App</p>
                    </li>
                    <li>
                        <h3 class="css-4ioq5i">
                            <span class="css-1rsqs3x">React</span>
                        </h3>
                        <p class="css-1h3py4p">Web</p>
                    </li>
                    <li>
                        <h3 class="css-4ioq5i">
                            <span class="css-1rsqs3x">Node JS</span>
                        </h3>
                        <p class="css-1h3py4p">Backend</p>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default Stats
