import './index.css'

const Projects = () => {
    return (
        <section class="css-hxrw9y">
            <h2 class="css-149wzhi">Current Projects we work on.</h2>
            <p class="css-1macpfm">
                Cross technology, cross industry.
            </p>
            <div class="css-1m0aqcb">
                <a href="" class="css-13jb3xt">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-label="Organisation" role="img"
                        fill="none" stroke="currentColor">
                        <defs>
                            <linearGradient id="Organisation-grad1" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad1-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad1-2)"></stop>
                            </linearGradient>
                            <linearGradient id="Organisation-grad2" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad2-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad2-2)"></stop>
                            </linearGradient>
                            <linearGradient id="Organisation-grad3" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad3-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad3-2)"></stop>
                            </linearGradient>
                            <linearGradient id="Organisation-grad4" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad4-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad4-2)"></stop>
                            </linearGradient>
                            <linearGradient id="Organisation-grad5" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad5-2)"></stop>
                                <stop offset="100%" stop-color="var(--grad5-1)"></stop>
                            </linearGradient>
                            <linearGradient id="Organisation-logo" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad1-2)"></stop>
                                <stop offset="100%" stop-color="var(--grad1-1)"></stop>
                            </linearGradient>
                        </defs>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4">
                        </path>
                    </svg>
                    <h2 class="css-1halcr3">Mobile Health Platform</h2>
                    <p class="css-1ixg90a">
                        Health Apps for cancer patients and families to get better treatment by clinical centers in Germany.
                    </p>
                </a>
                <a href="" class="css-1mvffzg">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-label="Code" role="img" fill="none"
                        stroke="currentColor">
                        <defs>
                            <linearGradient id="Code-grad1" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad1-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad1-2)"></stop>
                            </linearGradient>
                            <linearGradient id="Code-grad2" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad2-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad2-2)"></stop>
                            </linearGradient>
                            <linearGradient id="Code-grad3" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad3-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad3-2)"></stop>
                            </linearGradient>
                            <linearGradient id="Code-grad4" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad4-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad4-2)"></stop>
                            </linearGradient>
                            <linearGradient id="Code-grad5" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad5-2)"></stop>
                                <stop offset="100%" stop-color="var(--grad5-1)"></stop>
                            </linearGradient>
                            <linearGradient id="Code-logo" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad1-2)"></stop>
                                <stop offset="100%" stop-color="var(--grad1-1)"></stop>
                            </linearGradient>
                        </defs>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"></path>
                    </svg>
                    <h2 class="css-1halcr3">Test Automation SAP</h2>
                    <p class="css-1ixg90a">
                        Enable SAP customers to do automated unit testing <i>without</i> coding.
                    </p>
                </a>
                <a href="" class="css-1pedsd1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-label="Content" role="img" fill="none"
                        stroke="currentColor">
                        <defs>
                            <linearGradient id="Content-grad1" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad1-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad1-2)"></stop>
                            </linearGradient>
                            <linearGradient id="Content-grad2" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad2-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad2-2)"></stop>
                            </linearGradient>
                            <linearGradient id="Content-grad3" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad3-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad3-2)"></stop>
                            </linearGradient>
                            <linearGradient id="Content-grad4" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad4-1)"></stop>
                                <stop offset="100%" stop-color="var(--grad4-2)"></stop>
                            </linearGradient>
                            <linearGradient id="Content-grad5" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad5-2)"></stop>
                                <stop offset="100%" stop-color="var(--grad5-1)"></stop>
                            </linearGradient>
                            <linearGradient id="Content-logo" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                <stop offset="0%" stop-color="var(--grad1-2)"></stop>
                                <stop offset="100%" stop-color="var(--grad1-1)"></stop>
                            </linearGradient>
                        </defs>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z">
                        </path>
                    </svg>
                    <h2 class="css-1halcr3">Taxes</h2>
                    <p class="css-1ixg90a">
                        Creating next generation web based tax software for Enterprises.
                    </p>
                </a>
            </div>
        </section>
    )
}

export default Projects
