import React from 'react'
import Team from '../../Sections/Team'
import Intro from '../../Sections/Intro'
import Stats from '../../Sections/Stats'
import './index.css'
import Features from '../../Sections/Features'
import Positions from '../../Sections/Positions'
import Projects from '../../Sections/Projects'
import About from '../../Sections/About'

const Main = () => {
  return (
    <div id="__next">
      <div class="css-16kr4h2">
        <main id="skip-link-content" class="css-1ajlptq">
          <div class="css-qgl3pz">
            <Intro />
            <Stats />
            <Team />
            <Features />
            <Positions />
            <Projects />
            <About />
          </div>
        </main>
      </div>
    </div>
    )
}

export default Main
