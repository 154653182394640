import React from 'react'
import './index.css'

const Team = () => {
    return (
        <section class="css-1i806rk">
            <div class="css-1716r7p">
                <a href="" target="_blank" rel="noopener noreferrer" class="css-edd3rw">
                    <img src="team/irakli.jpg" class="css-1th9lgz" />@ Irakli
                </a>
                <br/>
                <p class="css-1h3py4p">
                    I love coding Node JS and to explore new technologies on my own.
                </p>
            </div>

            <div class="css-1716r7p">
                <a href="" target="_blank" rel="noopener noreferrer" class="css-edd3rw">
                    <img src="team/josh.jpg" class="css-1th9lgz" />@ Josh
                </a>
                <br/>
                <p class="css-1h3py4p">
                    We are located in Tbilisi, Georgia, but I prefer to work from where I like.
                </p>
            </div>

            <div class="css-1716r7p">
                <a href="" target="_blank" rel="noopener noreferrer" class="css-edd3rw">
                    <img src="team/temo.jpg" class="css-1th9lgz" />@ Temo
                </a>
                <br/>
                <p class="css-1h3py4p">
                    Bridging regulations and technologies is a challenge but also a chance.
                </p>
            </div>
        </section>
    )
}

export default Team
