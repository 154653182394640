import React from 'react'
import './index.css'

const Positions = () => {
    return (
        <section id="positions" class="css-50qodz">
          <h2 class="css-1wt7vt6">
            Open <span class="css-2ztw5j">Positions</span>
          </h2>
          <p class="css-1r2rgg1">
            We are about to post open position details here soon, stay tuned. Meanwhile you can also check jobs.ge for latest updates.
          </p>          
        </section>
    )
}

export default Positions
