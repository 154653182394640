import './index.css'
const Intro = () => {
    return (
        <>
            <div class="css-1es7w0s">
                <h1 class="css-9vlzxo">
                    A place for <span class="css-1rsqs3x">developers</span>.
                </h1>
                <p class="css-1ehzjxu">
                    No bullshit, just coding. 
                </p>
                <p class="css-1ehzjxu">
                    We develop End 2 End mobile / web applications and implement SAP projects using our own software for international customers.
                </p>
            </div>
            <div class="css-7e0dsk">
                <div class="css-xjwl54">
                    We are part of Exoriens GmbH, Berlin
                    <button class="css-y9fzud">
                    </button>
                </div>
                <a class="css-sg8y84" href="https://www.xgile.com">
                    <span class="css-whh5e5">
                        Get me there
                        <svg xmlns="https://www.xgile.com" viewBox="0 0 24 24" aria-label="Arrow right" role="img"
                            fill="none" stroke="currentColor">
                            <defs>
                                <linearGradient id="ArrowR-grad1" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                    <stop offset="0%" stop-color="var(--grad1-1)"></stop>
                                    <stop offset="100%" stop-color="var(--grad1-2)"></stop>
                                </linearGradient>
                                <linearGradient id="ArrowR-grad2" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                    <stop offset="0%" stop-color="var(--grad2-1)"></stop>
                                    <stop offset="100%" stop-color="var(--grad2-2)"></stop>
                                </linearGradient>
                                <linearGradient id="ArrowR-grad3" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                    <stop offset="0%" stop-color="var(--grad3-1)"></stop>
                                    <stop offset="100%" stop-color="var(--grad3-2)"></stop>
                                </linearGradient>
                                <linearGradient id="ArrowR-grad4" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                    <stop offset="0%" stop-color="var(--grad4-1)"></stop>
                                    <stop offset="100%" stop-color="var(--grad4-2)"></stop>
                                </linearGradient>
                                <linearGradient id="ArrowR-grad5" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                    <stop offset="0%" stop-color="var(--grad5-2)"></stop>
                                    <stop offset="100%" stop-color="var(--grad5-1)"></stop>
                                </linearGradient>
                                <linearGradient id="ArrowR-logo" x1="0%" x2="50%" y1="0%" y2="71.9%">
                                    <stop offset="0%" stop-color="var(--grad1-2)"></stop>
                                    <stop offset="100%" stop-color="var(--grad1-1)"></stop>
                                </linearGradient>
                            </defs>
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                        </svg>
                    </span>
                </a>
            </div>
        </>
    )
}

export default Intro
