import React from 'react'
import Logo from '../../Components/Logo';
import './index.css';

const Footer = () => {
    return (
        <footer className="css-168ojqm">
            <div className="css-1ajlptq">
                <div className="css-1n4jkyt">
                    <div className="css-1tbglje" style={{display: "grid"}}>
                        <h3 className="css-1l9an7p">2022</h3>
                        exoriens LLC, Rustaveli Avenue 24,
                        0108 Tbilisi,
                        Georgia
                    </div>
                </div>                
            </div>
        </footer>
    )
}

export default Footer