import Footer from "./layout/Footer";
import Header from "./layout/Header/HeaderNavigation";
import Main from "./layout/Main";
import React, { useEffect, useState } from 'react';
import {keepTheme} from './Utils/themes'

function App() {
  useEffect(() => {
    keepTheme()
  })
  return (
    <div className="App">
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
